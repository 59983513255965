.container {
    --border-color: #E0E4E8;
    --error-color: #EF4444;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.inputs {
    display: flex;
    border: 1px solid var(--border-color) !important;
    border-radius: 4px;
}

.input {
    width: calc(100% / 4);
}

.input input {
    position: relative;
    width: 100%;
    height: 112px;
    font-size: 36px!important;
    text-align: center;
    background: white;
    border-right: 1px solid var(--border-color) !important;
    box-shadow: 0 0 0 1px var(--border-color);
    transition: 0.3s;
    color: black;
}

.error > input {
    box-shadow: 0 0 0 1px var(--error-color);
}

.inputs [data-id="0"] {
    border-radius: 4px 0 0 4px;
}

.inputs [data-id="3"] {
    border-radius: 0 4px 4px 0;
    border-right: unset !important;
}

.input [data-active='true'] {
    z-index: 100;
    border-radius: 4px !important;
    box-shadow: 0 0 0 3px #d0d7f1;
    outline: none;
}
