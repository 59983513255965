.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide {
  padding-bottom: 50px;
}

.swiper-pagination-bullet {
    width: 12px!important;
    height: 12px!important;
    text-align: center;
    margin: 0 15px!important;
    line-height: 20px;
    font-size: 20px;
    opacity: 1;
    background-color: #fff!important;
    border: 2px solid #BABECB!important;
}

.swiper-pagination-bullet-active {
    border: 4px solid #4F46E5!important;
    background-color: #fff!important;
}
